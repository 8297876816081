import { useForm } from 'react-hook-form'
import { t, Trans } from '@lingui/macro'
import { Button, Spinner } from '@/src/ui'
import {
  OrganizationsProfiles,
  Profile,
  ProfileInsert,
  ProfileOrgVariableSalary,
  ProfileOrgVariableSalaryInsert,
  ProfileWithJoins,
  Team,
  TeamInsert,
  TeamProfile,
  TeamProfileInsert
} from '@/types'
import { BtnTypes } from '@/src/ui/button'
import { useOrgContext } from '@/hooks/state/organization'
import { NumberInput, TextInput } from '@tremor/react'
import Select, { SelectItem } from '@/src/stories/Select'
import useSupabaseTable from '@/hooks/supabase-table'
import { useQueryClient } from 'react-query'
import { QueryKeys } from '@/hooks/queryKeys'
import React, { useState } from 'react'
import { endOfYear, format, startOfYear } from 'date-fns'
import { IconWrapper } from '@/src/stories/IconWrapper'
import { Users01, UserUp01 } from '@untitled-ui/icons-react'
import { Enums, Tables, TablesInsert } from '@/db_types'

type FormProps = {
  profile: ProfileWithJoins
  afterSubmit?: () => void
}

interface FormValues {
  name: string
  email: string
  role: Enums<'org_role'>
  id: string
  type: string | null
  team?: number
  [i: string]: string | number | null | undefined
}

const FormEditProfile = ({ profile, afterSubmit }: FormProps) => {
  const queryClient = useQueryClient()

  const selectedOrganizationId = useOrgContext(state => state.id)

  const [variableSalary, setVariableSalary] = useState<number>()

  const organizationProFileRelationship = profile.organizations_profiles.find(
    org => org.organization_id === selectedOrganizationId
  )!

  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      email: profile?.email,
      id: profile?.id,
      name: profile?.name,
      type: organizationProFileRelationship.type,
      role: organizationProFileRelationship.role,
      team: profile.teams?.[0]?.id,
      ...((profile.organizations_profiles[0]?.meta as {
        [index: string]: string | number | null | undefined
      }) ?? {})
    } as FormValues
  })

  const { items: teams, isLoading } = useSupabaseTable<Team, TeamInsert>({
    tableName: 'teams'
  })

  const { items: customFields } = useSupabaseTable<
    Tables<'custom_fields'>,
    TablesInsert<'custom_fields'>
  >({
    tableName: 'custom_fields',
    selectQuery: '*',
    eqFilters: [
      {
        column: 'table_name',
        value: 'organizations_profiles'
      }
    ],
    order: [{ column: 'label', options: { ascending: true } }],
    queryKeys: [
      QueryKeys.custom_fields.get,
      selectedOrganizationId,
      'organizations_profiles'
    ]
  })

  const { updateItem: updateOrgProfile } = useSupabaseTable<
    OrganizationsProfiles,
    undefined
  >({
    enabled: false,
    tableName: 'organizations_profiles',
    afterUpdate: () => {
      queryClient.invalidateQueries([QueryKeys.profiles.get])
    }
  })

  const {
    items: teams_profiles,
    insertItem: insertTeamProfile,
    deleteJoinTableItem: deleteTeamProfile
  } = useSupabaseTable<TeamProfile, TeamProfileInsert>({
    tableName: 'teams_profiles',
    eqFilters: [
      {
        column: 'profile_id',
        value: profile.id
      }
    ],
    afterDelete: () => {
      queryClient.invalidateQueries([QueryKeys.profiles.get])
      queryClient.invalidateQueries([QueryKeys.teams_profiles.get, profile.id])
      queryClient.invalidateQueries([QueryKeys.plans.get])
    },
    afterInsert: () => {
      queryClient.invalidateQueries([QueryKeys.profiles.get])
      queryClient.invalidateQueries([QueryKeys.teams_profiles.get, profile.id])
      queryClient.invalidateQueries([QueryKeys.plans.get])
    }
  })

  const { updateItem: updateProfile } = useSupabaseTable<
    Profile,
    ProfileInsert
  >({
    enabled: false,
    queryKeys: [QueryKeys.profiles.get],
    tableName: 'profiles',
    afterUpdate: () => {
      if (variableSalary) {
        upsertVariableSalary.mutate([
          {
            profile_id: profile.id,
            organization_id: selectedOrganizationId,
            period_start: format(startOfYear(new Date()), 'yyyy-MM-dd'),
            period_end: format(endOfYear(new Date()), 'yyyy-MM-dd'),
            amount: variableSalary
          }
        ])
      } else {
        deleteVariableSalary.mutate({
          profile_id: profile.id,
          organization_id: selectedOrganizationId!,
          period_start: format(startOfYear(new Date()), 'yyyy-MM-dd')
        })
      }

      afterSubmit?.()
    }
  })

  const {
    upsertItem: upsertVariableSalary,
    deleteJoinTableItem: deleteVariableSalary
  } = useSupabaseTable<
    ProfileOrgVariableSalary,
    ProfileOrgVariableSalaryInsert
  >({
    tableName: 'profile_org_variable_salary',
    queryKeys: [
      QueryKeys.profile_org_variable_salary.get,
      profile.id,
      selectedOrganizationId
    ],
    eqFilters: [
      {
        column: 'profile_id',
        value: profile.id
      },
      {
        column: 'period_start',
        value: format(startOfYear(new Date()), 'yyyy-MM-dd')
      },
      {
        column: 'period_end',
        value: format(endOfYear(new Date()), 'yyyy-MM-dd')
      }
    ],
    upsertOptions: {
      onConflict: 'profile_id, organization_id, period_start'
    },
    afterQuery: ({ items }) => {
      setVariableSalary(items?.[0]?.amount)
    }
  })

  const onSubmit = async (data: FormValues) => {
    const { role, type, team, ...rest } = data

    const meta: { [index: string]: string | number | null | undefined } = {}
    customFields?.forEach(field => {
      meta[field.name] = rest[field.name]
      delete rest[field.name]
    })

    await updateProfile.mutateAsync({
      ...rest,
      id: profile.id
    })

    await updateOrgProfile.mutateAsync({
      id: { profile_id: profile.id, organization_id: selectedOrganizationId! },
      meta
    })

    if (profile.teams?.[0] && profile.teams?.[0].id !== team) {
      await deleteTeamProfile.mutateAsync({
        team_id: profile.teams[0].id,
        profile_id: profile.id
      })
    }

    if (team) {
      await insertTeamProfile.mutateAsync([
        {
          team_id: team,
          profile_id: profile.id,
          organization_id: selectedOrganizationId!
        }
      ])
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='flex flex-col gap-6 w-full'
      noValidate>
      <input type='hidden' {...register('id')} />

      <div className={'space-y-2'}>
        <label htmlFor='name' className='label'>
          <Trans>Name</Trans>*
        </label>
        <TextInput id={'name'} {...register('name', { required: true })} />
      </div>

      <div className={'space-y-2'}>
        <label htmlFor='email' className='label'>
          <Trans>Email</Trans>*
        </label>
        <TextInput id={'email'} {...register('email', { required: true })} />
      </div>

      <div className={'space-y-2'}>
        <Select
          id={'role'}
          label={t`Role`}
          iconLeading={<IconWrapper width={16} Icon={UserUp01} />}
          selectedKey={watch('role')}
          enableClear={false}
          items={[
            { key: 'user', label: t`User` },
            { key: 'supervisor', label: t`Supervisor` },
            { key: 'admin', label: t`Admin` }
          ]}
          onSelectionChange={value => {
            setValue('role', value as Enums<'org_role'>)
            updateOrgProfile.mutate({
              id: {
                organization_id: selectedOrganizationId!,
                profile_id: profile.id
              },
              role: value as Enums<'org_role'>
            })
          }}>
          {item => <SelectItem label={item.label} id={item.key} />}
        </Select>
      </div>

      <div className={'space-y-2'}>
        <label htmlFor='type' className='label'>
          <Trans>User type</Trans>
        </label>
        <TextInput
          placeholder={t`Add an specific type to this profile. For example: "Renewals"`}
          id={'type'}
          value={watch('type') ?? undefined}
          onChange={e => setValue('type', e.target.value)}
          onBlur={e => {
            setValue('type', e.target.value)
            updateOrgProfile.mutate({
              id: {
                organization_id: selectedOrganizationId!,
                profile_id: profile.id
              },
              type: e.target.value
            })
          }}
        />
      </div>

      <div className={'space-y-2'}>
        <label htmlFor='variableSalary' className='label'>
          <Trans>Annual Variable salary</Trans>
        </label>
        <NumberInput
          placeholder={t`Enter amount`}
          id={'variableSalary'}
          value={variableSalary?.toString() ?? ''}
          onValueChange={value => setVariableSalary(value)}
        />
      </div>

      <div className='space-y-4'>
        <Select
          id={'team'}
          label={t`Team`}
          iconLeading={<IconWrapper width={16} Icon={Users01} />}
          selectedKey={watch('team')}
          enableClear={true}
          items={teams}
          onSelectionChange={v => setValue('team', v as number)}>
          {item => <SelectItem label={item.name} id={item.id} />}
        </Select>
      </div>

      {customFields?.map(field => (
        <div key={field.name} className='space-y-2'>
          <label htmlFor={field.name} className='label'>
            {field.label}
          </label>
          <TextInput
            placeholder={t`Set value for ${field.label}`}
            id={field.name}
            {...register(field.name, { required: false })}
          />
        </div>
      ))}

      <div className='flex justify-end mt-4'>
        <Button btnType={BtnTypes.PRIMARY} type='submit' block>
          <Trans>Done</Trans>
        </Button>
      </div>
    </form>
  )
}

export default FormEditProfile
