import { useForm } from 'react-hook-form'
import { Button, Input } from '@/src/ui'
import { TeamInsert } from '@/types'
import { InputSizes, InputTypes } from '@/src/ui/input'
import useTeams from '@/hooks/team'
import { showToastError } from '@/utils/messages'
import { useState } from 'react'
import { useOrgContext } from '@/hooks/state/organization'
import { t, Trans } from '@lingui/macro'

const FormCreateNewTeam = ({ onDone }: { onDone?(): void }) => {
  const selectedOrganizationId = useOrgContext(state => state.id)

  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: ''
    }
  })

  const { insertTeam } = useTeams({
    enabled: false,
    setLoading,
    afterInsert: () => {
      onDone?.()
    }
  })

  const createTeam = async (team: Pick<TeamInsert, 'name'>) => {
    if (!selectedOrganizationId) {
      showToastError(new Error(t`No organization selected`))
      return
    }

    insertTeam.mutate({ ...team, organization_id: selectedOrganizationId })
  }

  const onSubmit = async (formData: Pick<TeamInsert, 'name'>) => {
    setLoading(true)
    createTeam(formData)
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='flex flex-col gap-4 w-full'>
      <Input
        inputType={!errors.name ? InputTypes.PRIMARY : InputTypes.DANGER}
        helperText={errors.name && (t`This team's name already exists`)}
        label={t`Team name`+'*'}
        inputSize={InputSizes.SMALL}
        {...register('name', { required: true })}
      />
      <div className='flex justify-end mt-4'>
        <Button loading={loading} type='submit'>
          <Trans>Save</Trans>
        </Button>
      </div>
    </form>
  )
}

export default FormCreateNewTeam
