import { Button, Input } from '@/src/ui'
import { BtnSizes } from '@/src/ui/button'
import { InputSizes } from '@/src/ui/input'
import { t, Trans } from '@lingui/macro'

type FormAddVariableProps = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const FormAddVariable = ({ onSubmit }: FormAddVariableProps) => {
  return (
    <form onSubmit={onSubmit} className={'flex flex-col space-y-4'}>
      <div className={'flex flex-col space-y-2'}>
        <Input
          id={'variable-name'}
          inputSize={InputSizes.SMALL}
          label={t`Name`}
          name={'variable-name'}
        />
      </div>
      <div className={'flex flex-col space-y-2'}>
        <Input
          id={'variable-value'}
          inputSize={InputSizes.SMALL}
          label={t`Value`}
          name={'variable-value'}
        />
      </div>
      <div className={'flex justify-end'}>
        <Button size={BtnSizes.SM} type='submit' block>
          <Trans>Add Variable</Trans>
        </Button>
      </div>
    </form>
  )
}

export default FormAddVariable
