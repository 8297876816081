import withI18n from '@/utils/withTranslations'
import { t, Trans } from '@lingui/macro'
import { TextField } from '@/src/stories/TextField'
import { Button } from '@/src/stories/Button'
import { Heading, Text } from '@/src/ui'
import { HeadingSizes } from '@/src/ui/typography/heading'
import { TextSizes } from '@/src/ui/typography/text'
import { useState } from 'react'
import { showToastError } from '@/utils/messages'
import Link from 'next/link'

type FormResetPasswordProps = {
  onSubmit: React.FormEventHandler
}

const FormResetPassword = (props: FormResetPasswordProps) => {
  const { onSubmit } = props
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (password !== passwordConfirmation) {
      showToastError(new Error(t`The two passwords don't match.`))
      return
    }

    if (password.length < 8) {
      showToastError(new Error(t`The password must be at least 8 characters.`))
      return
    }

    if (!/(?=.*[A-Z])(?=.*\d).+/.test(password)) {
      showToastError(
        new Error(
          t`The password must contain at least one uppercase letter and one number.`
        )
      )
      return
    }

    if (/\s/.test(password)) {
      showToastError(
        new Error(t`The password cannot contain whitespace characters.`)
      )
      return
    }

    onSubmit(event)
  }

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-col gap-2 text-center'>
        <Heading level={2} size={HeadingSizes['2XL']}>
          <Trans>Create new password</Trans>
        </Heading>
        <Text size={TextSizes.SM} color='text-primary/70'>
          <Trans>
            Your new password must be at least 8 characters <br />
            and have one uppercase letter and one number.
          </Trans>
        </Text>
      </div>
      <form className='flex flex-col gap-6' onSubmit={handleSubmit}>
        <div>
          <TextField
            id='password'
            placeholder={t`Your password` + '*'}
            type='password'
            name='password'
            isRequired
            value={password}
            onChange={e => setPassword(e)}
          />
        </div>
        <div>
          <TextField
            id='password-confirmation'
            placeholder={t`Confirm your password` + '*'}
            type='password'
            name='password-confirmation'
            isRequired
            value={passwordConfirmation}
            onChange={e => setPasswordConfirmation(e)}
          />
        </div>

        <div className={'space-y-4 flex flex-col items-center w-full'}>
          <Button
            className={'w-full justify-center'}
            label={t`Save new password`}
            type='submit'
          />
        </div>
        <Text size={TextSizes.SM} color='text-primary/70 whitespace-normal'>
          <Trans>
            Something gone wrong?{' '}
            <Link href='/forgot-password'>
              <u>Try again</u>
            </Link>
          </Trans>
        </Text>
      </form>
    </div>
  )
}

export const getServerSideProps = withI18n(ctx => {
  return {
    props: { translation: ctx.translation, locale: ctx.locale }
  }
})

export default FormResetPassword
