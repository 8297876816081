import { Button, Input } from '@/src/ui'
import { BtnTypes } from '@/src/ui/button'
import { InputSizes } from '@/src/ui/input'
import { useRef } from 'react'
import { Trans } from '@lingui/macro'

type FormEditDeleteVariableProps = {
  onUpdateVariable: ({
    variable,
    oldVariable
  }: {
    variable: Record<string, string | number | boolean>
    oldVariable: Record<string, string | number | boolean>
  }) => void
  onDeleteVariable: (variable: { [key: string]: string | number }) => void
  defaultValues?: {
    [key: string]: string | number
  }
}

const FormEditDeleteVariable = ({
  onUpdateVariable,
  onDeleteVariable,
  defaultValues
}: FormEditDeleteVariableProps) => {
  const values = Object.entries(defaultValues || {})
  const formRef = useRef<HTMLFormElement>(null)

  const getDataFromFormRef = () => {
    if (!formRef.current) return {}

    const formData = new FormData(formRef.current)
    const variableName = formData.get('variable-name') as string
    const variableValue = formData.get('variable-value') as string

    return {
      [variableName]: variableValue
    }
  }

  const handleUpdate = () => {
    onUpdateVariable({
      variable: getDataFromFormRef(),
      oldVariable: defaultValues || {}
    })
  }

  const handleDeleteVariable = () => {
    onDeleteVariable(getDataFromFormRef())
  }

  return (
    <form ref={formRef} className={'flex flex-col gap-4'}>
      <div className={'flex flex-col'}>
        <Input
          defaultValue={values[0]?.[0]?.toString()}
          id={'variable-name'}
          inputSize={InputSizes.SMALL}
          label='Name'
          name={'variable-name'}
        />
      </div>
      <div className={'flex flex-col'}>
        <Input
          defaultValue={values[0]?.[1]?.toString()}
          id={'variable-value'}
          inputSize={InputSizes.SMALL}
          label='Value'
          name={'variable-value'}
        />
      </div>
      <Button onClick={handleUpdate} className='mt-4' block>
        <Trans>Update</Trans>
      </Button>
      <span className='w-full h-px bg-primary-100 my-4' />
      <span className='text-center text-primary-400 text-sm mb-4'>
      <Trans>or delete the variable</Trans>
      </span>
      <Button onClick={handleDeleteVariable} btnType={BtnTypes.DANGER}>
      <Trans>Delete</Trans>
      </Button>
    </form>
  )
}

export default FormEditDeleteVariable
