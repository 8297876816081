import withI18n from '@/utils/withTranslations'
import { t, Trans } from '@lingui/macro'
import { TextField } from '@/src/stories/TextField'
import { Button } from '@/src/stories/Button'
import { ButtonLink, Heading, Text } from '@/src/ui'
import { HeadingSizes, HeadingWeights } from '@/src/ui/typography/heading'
import { TextSizes } from '@/src/ui/typography/text'

type FormForgotPasswordProps = {
  onSubmit: React.FormEventHandler
}

const FormForgotPassword = (props: FormForgotPasswordProps) => {
  const { onSubmit } = props
  return (
    <div className='flex flex-col gap-8 w-full sm:w-96 max-w-[400px]'>
      <div className='flex flex-col gap-2 text-center'>
        <Heading
          level={2}
          size={HeadingSizes['2XL']}
          weight={HeadingWeights.FONT_MEDIUM}>
          <Trans>Forgot your password?</Trans>
        </Heading>
        <Text size={TextSizes.SM} color='text-primary/70'>
          <Trans>No worries, we’ll send you reset instructions.</Trans>
        </Text>
      </div>

      <form className='flex flex-col gap-6 text-center' onSubmit={onSubmit}>
        <TextField
          id='email'
          placeholder={t`Your email`}
          type='email'
          name='email'
        />
        <div className={'space-y-4 flex flex-col items-center w-full'}>
          <Button
            className={'w-full justify-center'}
            label={t`Send`}
            type='submit'
          />
        </div>
      </form>

      <ButtonLink href='/login' className='text-center underline'>
        <Trans>Back to Login</Trans>
      </ButtonLink>
    </div>
  )
}

export const getServerSideProps = withI18n(ctx => {
  return {
    props: { translation: ctx.translation, locale: ctx.locale }
  }
})

export default FormForgotPassword
