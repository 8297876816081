import withI18n from '@/utils/withTranslations'
import { TextSizes } from '@/src/ui/typography/text'
import { t, Trans } from '@lingui/macro'
import { FormEvent, useState } from 'react'
import { useRouter } from 'next/router'
import { showToastError } from '@/utils/messages'
import { useAcceptInvite } from '@/hooks/auth'
import { Heading, Text } from '@/src/ui'
import { Button } from '@/src/stories/Button'
import { HeadingSizes, HeadingWeights } from '@/src/ui/typography/heading'
import { TextField } from '@/src/stories/TextField'

const FormSignup = () => {
  const [password, setPassword] = useState('')
  const router = useRouter()

  const isInvitation = router.query['invitation'] === 'true'

  const acceptInvite = useAcceptInvite(
    () => {
      router.push('/login')
    },
    error => {
      showToastError(error)
    }
  )

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (password.length < 8) {
      showToastError(new Error(t`The password must be at least 8 characters.`))
      return
    }

    if (!/(?=.*[A-Z])(?=.*\d).+/.test(password)) {
      showToastError(
        new Error(
          t`The password must contain at least one uppercase letter and one number.`
        )
      )
      return
    }

    if (/\s/.test(password)) {
      showToastError(
        new Error(t`The password cannot contain whitespace characters.`)
      )
      return
    }

    const params = router.asPath.split('#')[1].split('&')
    const parsedParams = Object.fromEntries(
      params.map(p => {
        const pv = p.split('=')
        return [pv[0], pv[1]]
      })
    )

    const formData = Object.fromEntries(
      new FormData(e.currentTarget).entries()
    ) as { password: string }

    acceptInvite.mutate({
      accessToken: parsedParams['access_token'],
      refreshToken: parsedParams['refresh_token'],
      password: formData.password
    })
  }

  if (!isInvitation) {
    return null
  }

  return (
    <div className='flex flex-col gap-6'>
      <Heading
        level={2}
        size={HeadingSizes['2XL']}
        weight={HeadingWeights.FONT_MEDIUM}>
        <Trans>Set up your password</Trans>
      </Heading>
      <form
        action='#'
        className='flex flex-col gap-6 w-96'
        onSubmit={handleSubmit}
        method='POST'>
        <Text size={TextSizes.SM} color='text-primary/70'>
          <Trans>
            Your new password must be at least 8 characters <br />
            and have one uppercase letter and one number.
          </Trans>
        </Text>
        <div>
          <TextField
            type='password'
            id='password'
            name='password'
            placeholder={t`Password` + '*'}
            value={password}
            onChange={e => setPassword(e)}
          />
        </div>
        <div className={'flex flex-col items-center w-full'}>
          <Button
            className={'w-full justify-center'}
            type='submit'
            label={t`Sign up`}
          />
        </div>
      </form>
    </div>
  )
}

export const getServerSideProps = withI18n(ctx => {
  return {
    props: { translation: ctx.translation, locale: ctx.locale }
  }
})

export default FormSignup
