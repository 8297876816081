import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next'
import { loadTranslation } from '@/utils/i18n'

const ACCEPTED_LOCALES = ['en', 'es']

const withI18n =
  (
    fn: (
      ctx: GetServerSidePropsContext & any
    ) => Promise<GetServerSidePropsResult<any>> | GetServerSidePropsResult<any>
  ) =>
  async (ctx: GetServerSidePropsContext & { profileAndOrgData?: any }) => {
    const isDashboard =
      ctx.resolvedUrl && /^((\/dashboard)|(\/builder))/.test(ctx.resolvedUrl)
    const clientLocale = ctx.req.headers['accept-language']
    const parsedClientLocale = clientLocale?.split(',')[0]
    let locale =
      parsedClientLocale && ACCEPTED_LOCALES.includes(parsedClientLocale)
        ? parsedClientLocale
        : ctx.locale ?? 'en'
    if (isDashboard && ctx.profileAndOrgData?.selectedOrganization) {
      locale = ctx.profileAndOrgData?.selectedOrganization.locale
    }

    const translation = await loadTranslation(locale)
    return fn({
      ...ctx,
      translation,
      locale
    })
  }

export default withI18n
